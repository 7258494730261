import CommonButton from 'components/common/button';
import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 436px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    height: auto;
    padding: 50px 20px 20px 20px;
    margin: 0 15px;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Text-text_default, #393939);
  text-align: center;
  /* Desktop/Extra Large-700 */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

export const ImageContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

export const Description = styled.div`
  color: var(--Text-secondary, #4a4e62);
  text-align: center;
  /* Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

export const ButtonCancel = styled(CommonButton)`
  max-width: 190px;
  padding: 0 40px;
  height: 72px;
  color: var(--Text-text_default, #393939);

  border: var(--Borders-border_2px, 2px) solid var(--Buttons_Text-text_default, #393939);

  @media (max-width: 760px) {
    width: 45%;
    padding: 0 10px;
    height: 52px;
    font-size: 18px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  width: 100%;

  @media (max-width: 760px) {
    gap: 18px;
  }
`;

export const ButtonDelete = styled(ButtonCancel)`
  min-width: 190px !important;
  height: 72px;
  border-radius: var(--Corner-Radius-corner_24px, 24px);
  border: var(--Borders-border_2px, 2px) solid var(--Notifications-notif_error, #e65218);
  background: var(--Background-bg_white, #fff);
  color: var(--Notifications-notif_error, #e65218);
  width: max-content;
  @media (max-width: 760px) {
    padding: 16px 26px;
    min-width: unset !important;
    max-width: 70% !important;
    font-size: 18px;
    height: 52px;
  }
`;
