import { Amplitude } from 'services/analytics/amplitude';
import { AnalyticsEvent } from 'services/analytics/events';

export const Analytics = {
  sendEvent: async (event: AnalyticsEvent) => {
    Amplitude.track(event);
  },
  updateUser: (data: AnalyticsUserProp | AnalyticsUserProp[]) => {
    Amplitude.updateUser(data);
  },
  setUserId: (userId: string) => {
    Amplitude.setUserId(userId);
  },
  getCurrentPageName: () => {
    const path = window.location.pathname;
    const woSlash = path.replace('/', '');

    const locales = ['fr', 'de', 'it', 'ja', 'es', 'pl', 'tr'];

    const urlFirstSegment = woSlash.split('/')[0];

    const selectedLocale = locales.includes(urlFirstSegment) ? urlFirstSegment : null;

    const woLocales = selectedLocale ? `${woSlash.slice(3)}` : woSlash;

    // converters
    if (path.includes('-to-')) {
      return woLocales.replace(/-/g, '_');
    }

    if (window.location.pathname.includes('reset-password')) {
      return 'restore';
    }

    if (window.location.pathname === '/' || window.location.pathname === '') {
      return 'main_page';
    }

    // Handle localized main pages
    if (locales.includes(urlFirstSegment) && (!woLocales || woLocales === '')) {
      return `/${urlFirstSegment}/main_page`;
    }

    return woLocales;
  },
  getCurrentPageLocale: () => {
    const path = window.location.pathname;
    const woSlash = path.replace('/', '');

    const locales = ['fr', 'de', 'it', 'ja', 'es', 'pl', 'tr'];

    const urlFirstSegment = woSlash.split('/')[0];

    const selectedLocale = locales.includes(urlFirstSegment) ? urlFirstSegment : null;

    const woLocales = selectedLocale ? `${woSlash.slice(3)}` : woSlash;

    // converters
    if (path.includes('-to-')) {
      return woSlash.replace(/-/g, '_');
    }


    if (window.location.pathname.includes('reset-password')) {
      return 'restore';
    }

    if (window.location.pathname === '/' || window.location.pathname === '') {
      return 'main_page';
    }

    // Handle localized main pages
    if (locales.includes(urlFirstSegment) && (!woLocales || woLocales === '')) {
      return `/${urlFirstSegment}/main_page`;
    }

    return woLocales;
  },
};

export interface AnalyticsService {
  init: () => void;
  track: (event: AnalyticsEvent) => void;
  updateUser: (data: AnalyticsUserProp | AnalyticsUserProp[]) => void;
  setUserId: (userId: string) => void;
}

export interface AnalyticsUserProp {
  key: string;
  value: string | number | boolean | string[];
}
