import { combineReducers, Reducer } from 'redux';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import loading from './loading';
import user from './user';
import modals from './modals';
import subscriptions from './subscriptions';
import documents from './documents';
import analytics from './analytics';

const combinedReducer = combineReducers({
  loading,
  user,
  modals,
  subscriptions,
  documents,
  analytics
});

const rootReducer: Reducer = (state: any, action: IReduxAction) => {
  return combinedReducer(state, action);
};

export default rootReducer;
