import CommonLink from 'components/common/link';
import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

import { ReactComponent as IcEdit } from 'assets/icon-edit.svg';
import { ReactComponent as IcRightArrowBox } from 'assets/right_arrow_box.svg';
import { ReactComponent as IcForms } from 'assets/forms-icon.svg';

export const Container = styled.div`
  width: 883px;
  min-height: 542px;
  height: auto;
  border-radius: 20px;
  padding: var(--Radius-l, 20px) var(--Radius-l, 20px) var(--Radius-2xl, 40px) var(--Radius-l, 20px);
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .ant-progress-inner {
    height: 20px;
    background: var(--Primary-10, #ebeeff);

    .ant-progress-bg {
      height: 20px !important;
      background: #4988fc;
    }
  }

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
    min-height: max-content;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Text-color-tittle, #212e45);
  text-align: center;

  /* Desktop/Heading/H4 */

  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 29.9px */
  margin-bottom: 5px;

  @media (max-width: 760px) {
    font-size: 19px;
    margin-bottom: 15px;
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  display: flex;
  width: 16px;
  height: 16px;
  margin: 8px 0;
  margin-left: auto;
`;

export const TabsContainer = styled.div`
  padding: 4px 20px;

  .ant-tabs-nav {
    &:before {
      border-bottom: none !important;
    }

    margin-bottom: 20px !important;
  }

  .ant-tabs-tab {
    padding-bottom: 10px 0 !important;
    .ant-tabs-tab-btn {
      color: var(--Text-secondary, #4c5e7f) !important;
      text-align: center;
      /* Body/Med */

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      white-space: pre-line !important;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid var(--Primary-primary-50, #4988fc) !important;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--Primary-primary-50, #4988fc) !important;
    }
  }

  @media (max-width: 760px) {
    display: none;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 30px var(--Radius-xl, 30px);
  flex-wrap: wrap;
  margin-top: 4px;
`;

export const ActionItem = styled(CommonLink)`
  display: flex;
  width: 177px;
  height: 152px;
  padding: var(--Radius-xl, 30px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Radius-s, 10px);
  border-radius: var(--Radius-s, 10px);
  background: var(--Blue-white, #fff);
  border: 1px solid transparent;
  cursor: pointer;
  /* shadow */
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);

  &:hover {
    border-radius: 10px;
    border: 1px solid #4988fc;
    background: #f3f5ff;
    box-shadow: 0px 4px 12px 0px rgba(0, 14, 79, 0.12);
    text-decoration: none !important;
  }
`;

export const ActionTitle = styled.div`
  color: var(--Text-color-main, #212e45);
  text-align: center;
  /* Desktop/Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

export const ActionImage = styled.img`
  width: 52px;
  height: 60px;
`;

export const MobileContainer = styled.div`
  display: none;
  padding: 0 10px;

  .ant-collapse-item {
    &:not(:last-child) {
      border-bottom: 1px solid #dfe4ea;
    }
  }

  .ant-collapse-header {
    padding: 12px 0 !important;
  }

  .ant-collapse-header-text {
    color: var(--Text-color-subtitle, #4c5e7f);
    /* Desktop/Body/Uppercase */

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
  }

  .ant-collapse-item-active {
    svg {
      path {
        fill: #4988fc;
      }
    }
    .ant-collapse-header-text {
      color: #4988fc;

      svg {
        path {
          fill: #4988fc;
        }
      }
    }
  }

  @media (max-width: 760px) {
    display: block;
  }
`;

export const MobileActions = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px var(--Radius-s, 10px);
  align-self: stretch;
  flex-wrap: wrap;
`;

export const MobileActionItem = styled(CommonLink)`
  display: flex;
  padding: var(--Radius-s, 10px) var(--Radius-m, 15px);
  align-items: center;
  gap: var(--Radius-s, 10px);
  border-radius: 6px;
  color: var(--Text-color-tittle, #212e45) !important;
  text-align: center;
  /* Desktop/Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  background-color: rgba(48, 86, 211, 0.04) !important;
`;

export const MobileActionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const RightArrowBoxImage = styled(IcRightArrowBox)<{ open?: boolean }>`
  path {
    fill: ${(props) => (props.open ? '#4988FC' : '#354562')};
  }
`;

export const LeftArrowBoxImage = styled(IcRightArrowBox)<{ open?: boolean }>`
  rotate: 180deg;
  path {
    fill: ${(props) => (props.open ? '#4988FC' : '#354562')};
  }
`;

export const FormsIcon = styled(IcForms)<{ open?: boolean }>`
  path {
    fill: ${(props) => (props.open ? '#4988FC' : '#354562')};
  }
`;

export const VectorEditImage = styled(IcEdit)<{ open?: boolean }>`
  path {
    fill: ${(props) => (props.open ? '#4988FC' : '#354562')};
  }
`;
