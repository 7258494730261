import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 360px;
  height: 450px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-progress-inner {
    height: 20px;
    background: var(--Primary-10, #ebeeff);

    .ant-progress-bg {
      height: 20px !important;
      background: #4988fc;
    }
  }

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Colors-black-grey-white-main, #1d1d1d);
  margin-bottom: 25px;
`;

export const ProgressValue = styled.span`
  color: var(--Primary-primary-50, #4988fc);
  /* Heading/H4 */

  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 33.6px */
  letter-spacing: 0.04em;
  font-variant-numeric: tabular-nums;
`;

export const AnimationIcon = styled.div`
  width: 122px;
  height: 146px;
  margin-bottom: 46px; /* required to properly set spacing to make it similar to design */

  /* remove once animation is rounded in figma and updated */
  border-radius: 20px;
  overflow: hidden;
`;

export const AnimationSteps = styled.div`
  width: 250px;
  height: 108px;
  margin-top: 20px; /* required to properly set spacing to make it similar to design */
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
`;

export const StepsItem = styled.div`
  opacity: 0; /* Start with hidden opacity */
  transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
  color: var(--Colors-black-grey-white-main, #1d1d1d);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  gap: 8px;
  display: flex;
  align-items: center;
`;
