import styled from 'styled-components';
import { ButtonHeight } from 'ts/enums/styles';
import { ButtonProps } from 'ts/types/button.props';

export const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  width: ${(props) => props?.width || '100%'};
  justify-content: center;
  align-items: center;
  padding: 16px 32px;

  gap: 8px;
  border-radius: 24px;
  font-family: Arial;
  cursor: ${(props) => (props?.disabled ? 'not-allowed' : 'pointer')};

  text-align: center;
  /* Desktop/Extra Large-700 */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: ${(props) => {
    if (props.disabled || !props?.type) return '600';
    switch (props?.type) {
      case 'primary':
        return '700';
      case 'secondary':
        return '600';
      default:
        return '600';
    }
  }};
  line-height: 32px; /* 133.333% */
  outline: none;
  min-width: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.size ? props.size : ButtonHeight.LARGE)};
  border: none;
  border: ${(props) => {
    if (props.disabled || !props?.type) return '1px solid #E5E7EB';
    switch (props?.type) {
      case 'primary':
        return '1px solid #4988FC';
      case 'danger':
        return '1px solid #E10E0E';
      case 'secondary':
        return '1px solid #4988FC';
      case 'tertiary':
        return '1px solid #DFE4EA';
      default:
        return '';
    }
  }};

  background: ${(props) => {
    if (props.disabled || !props?.type) return '#E5E7EB';
    switch (props?.type) {
      case 'primary':
        return '#4988FC';
      case 'danger':
        return '#E10E0E';
      case 'secondary':
      case 'tertiary':
        return '#FFF';
      default:
        return '';
    }
  }};
  color: ${(props) => {
    if (props?.disabled || !props?.type) return '#6B7280';
    switch (props?.type) {
      case 'primary':
      case 'danger':
        return '#FFF';
      case 'secondary':
        return '#393939';
      case 'tertiary':
        return '#4988FC';
      default:
        return '#4C5E7F';
    }
  }};

  &:hover {
    background: ${(props) => {
      if (props.disabled || !props?.type) return '#E5E7EB';
      switch (props?.type) {
        case 'primary':
          return '#649AFF';
        case 'danger':
          return '#C90909';
        case 'secondary':
          return '#FFF';
        default:
          return '';
      }
    }};

    border: ${(props) => {
      if (props.disabled || !props?.type) return '1px solid #E5E7EB';
      switch (props?.type) {
        case 'primary':
        case 'secondary':
          return '1px solid #649AFF';
        case 'danger':
          return '1px solid #C90909';
        case 'tertiary':
          return '1px solid #DFE4EA';
        default:
          return '';
      }
    }};

    color: ${(props) => {
      if (props?.disabled || !props?.type) return '#6B7280';
      switch (props?.type) {
        case 'primary':
        case 'danger':
          return '#FFF';
        case 'secondary':
          return '#393939';
        case 'tertiary':
          return '#4988FC';
        default:
          return '#4C5E7F';
      }
    }};
    outline: none;
  }
  &:focus {
    transition: all 0.2s ease-in-out 0s;
  }

  &:active {
    background: ${(props) => {
      if (props.disabled || !props?.type) return '#E5E7EB';
      switch (props?.type) {
        case 'primary':
          return '#2F71EC';
        case 'danger':
          return '#C90909';
        case 'secondary':
        case 'tertiary':
          return '#EBEEFF';
        default:
          return '';
      }
    }};

    border: ${(props) => {
      if (props.disabled || !props?.type) return '1px solid #E5E7EB';
      switch (props?.type) {
        case 'primary':
        case 'secondary':
          return '1px solid #2F71EC';
        case 'danger':
          return '1px solid #C90909';
        case 'tertiary':
          return '1px solid #DFE4EA';
        default:
          return '';
      }
    }};

    color: ${(props) => {
      if (props?.disabled || !props?.type) return '#6B7280';
      switch (props?.type) {
        case 'primary':
        case 'danger':
          return '#FFF';
        case 'secondary':
          return '#393939';
        case 'tertiary':
          return '#4988FC';
        default:
          return '#4C5E7F';
      }
    }};
  }
`;

export const LinkButtonContainer = styled.button<ButtonProps>`
  border: none;
  background: none;
  color: #5766eb;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.disabled && 'pointer-events: none;'}
`;
