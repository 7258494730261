import { EServiceType, IService } from 'ts/interfaces/services/service';

export const converterServicesList = (t: any): IService[] => {
  return [
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-word',
      funnel: 'pdf_to_word',
      translateKey: 'service_page.pdf_to_word',
      name: t('service_page.pdf_to_word.name'),
      nameKey: 'service_page.pdf_to_word.name',
      from: 'PDF',
      to: 'DOC',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-png',
      funnel: 'pdf_to_png',
      translateKey: 'service_page.pdf_to_png',
      name: t('service_page.pdf_to_png.name'),
      nameKey: 'service_page.pdf_to_png.name',
      from: 'PDF',
      to: 'PNG',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-jpg',
      funnel: 'pdf_to_jpg',
      translateKey: 'service_page.pdf_to_jpg',
      name: t('service_page.pdf_to_jpg.name'),
      nameKey: 'service_page.pdf_to_jpg.name',
      from: 'PDF',
      to: 'JPG',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-epub',
      funnel: 'pdf_to_epub',
      translateKey: 'service_page.pdf_to_epub',
      name: t('service_page.pdf_to_epub.name'),
      nameKey: 'service_page.pdf_to_epub.name',
      from: 'PDF',
      to: 'EPUB',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-excel',
      funnel: 'pdf_to_excel',
      translateKey: 'service_page.pdf_to_excel',
      name: t('service_page.pdf_to_excel.name'),
      nameKey: 'service_page.pdf_to_excel.name',
      from: 'PDF',
      to: 'XLS',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-pptx',
      funnel: 'pdf_to_pptx',
      translateKey: 'service_page.pdf_to_pptx',
      name: t('service_page.pdf_to_pptx.name'),
      nameKey: 'service_page.pdf_to_pptx.name',
      from: 'PDF',
      to: 'PPTX',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/word-to-pdf',
      funnel: 'word_to_pdf',
      translateKey: 'service_page.word_to_pdf',
      name: t('service_page.word_to_pdf.name'),
      nameKey: 'service_page.word_to_pdf.name',
      from: 'DOC',
      to: 'PDF',
      availableFormats: '.doc, .docx',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/png-to-pdf',
      funnel: 'png_to_pdf',
      translateKey: 'service_page.png_to_pdf',
      name: t('service_page.png_to_pdf.name'),
      nameKey: 'service_page.png_to_pdf.name',
      from: 'PNG',
      to: 'PDF',
      availableFormats: '.png, .jpeg, .jpg, .webp, .heic, .jfif, .odt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/jpg-to-pdf',
      funnel: 'jpg_to_pdf',
      translateKey: 'service_page.jpg_to_pdf',
      name: t('service_page.jpg_to_pdf.name'),
      nameKey: 'service_page.jpg_to_pdf.name',
      from: 'JPG',
      to: 'PDF',
      availableFormats: '.jpg, .jpeg, .png, .heic, .webp, .jfif',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/epub-to-pdf',
      funnel: 'epub_to_pdf',
      translateKey: "service_page.epub_to_pdf",
      name: t('service_page.epub_to_pdf.name'),
      nameKey: 'service_page.epub_to_pdf.name',
      from: 'EPUB',
      to: 'PDF',
      availableFormats: '.epub',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/excel-to-pdf',
      funnel: 'excel_to_pdf',
      translateKey: 'service_page.excel_to_pdf',
      name: t('service_page.excel_to_pdf.name'),
      nameKey: 'service_page.excel_to_pdf.name',
      from: 'XLS',
      to: 'PDF',
      availableFormats: '.xls, .xlsx, .csv, .txt, .rtf, .ods, .odt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pptx-to-pdf',
      funnel: 'pptx_to_pdf',
      translateKey: 'service_page.pptx_to_pdf',
      name: t('service_page.pptx_to_pdf.name'),
      nameKey: 'service_page.pptx_to_pdf.name',
      from: 'PPTX',
      to: 'PDF',
      availableFormats: '.pptx, .ppt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/image-to-pdf',
      funnel: 'image_to_pdf',
      translateKey: 'service_page.image_to_pdf',
      name: t('service_page.image_to_pdf.name'),
      nameKey: 'service_page.image_to_pdf.name',
      from: 'Image',
      to: 'PDF',
      // TODO: Removed unsupported images
      // availableFormats: '.jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .eps',
      availableFormats: '.jpg, .jpeg, .png, .gif, .webp, .bmp',
    },
  ];
};
