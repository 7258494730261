import { notification } from 'antd';

import closeImagePath from 'assets/close-icon.svg';
import errorImagePath from 'assets/error-notification-icon.svg';
import infoImagePath from 'assets/info-notification-icon.svg';
import successImagePath from 'assets/success-notification-icon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ENotification, INotification } from 'ts/interfaces/common/notification';
import { CloseButton, ErrorIcon, SuccessIcon } from './styles';

const openNotification = ({ message, description, duration = 5, type, onClick }: INotification) => {
  let icon;
  switch (type) {
    case ENotification.ERROR: {
      icon = <ErrorIcon src={errorImagePath} alt="" />;
      break;
    }
    case ENotification.SUCCESS: {
      icon = <SuccessIcon src={successImagePath} alt="" />;
      break;
    }
    case ENotification.INFO: {
      icon = <SuccessIcon src={infoImagePath} alt="" />;
      break;
    }
    default:
      break;
  }

  notification.config({
    top: 88,
  });

  notification.open({
    style: {
      borderRadius: '8px',
    },
    className: `pdfleader-notification ${type}`,
    message,
    description,
    onClick: typeof onClick === 'function' ? onClick : undefined,
    duration,
    icon,
    closeIcon: (
      <CloseButton>
        <LazyLoadImage src={closeImagePath} alt="close icon" />
      </CloseButton>
    ),
  });
};

export default openNotification;
