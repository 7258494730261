import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import passwordHideIconPath from 'assets/password-hide.svg';
import passwordShowIconPath from 'assets/password-show.svg';

import {
  InputPassword,
  InputLabel,
  ErrorMessage,
  LockIcon,
  ForgotContainer,
  ForgotPasswordButton,
  SuggestionMessage,
} from './styles';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { useDispatch } from 'react-redux';

interface CommonInputPasswordDTO {
  onChange: unknown;
  value: string;
  placeholder?: string;
  label?: string;
  errorMessage?: string;
  onForgotPassword?: unknown;
  passwordInputRef?: any;
  suggestion?: string;
}

const CommonInputPassword: FC<CommonInputPasswordDTO> = ({
  label,
  value,
  placeholder,
  errorMessage,
  onChange,
  onForgotPassword,
  passwordInputRef,
  suggestion,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleChange = (value: string) => {
    if (typeof onChange === 'function') onChange(value);
  };

  const handleForgotPassword = () => {
    dispatch(sendAnalyticEvent({ event: 'forgot_password_tap' }));
    if (typeof onForgotPassword === 'function') onForgotPassword();
  };

  return (
    <>
      <ForgotContainer>
        {label && <InputLabel>{label}</InputLabel>}
        {typeof onForgotPassword === 'function' && (
          <ForgotPasswordButton onClick={handleForgotPassword}>
            {t('global.forgot_password')}?
          </ForgotPasswordButton>
        )}
      </ForgotContainer>

      <InputPassword
        name="password"
        type="password"
        placeholder={placeholder || ''}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        required
        iconRender={(visible) =>
          visible ? (
            <LockIcon style={{ marginBottom: '2px' }} src={passwordHideIconPath} alt="" />
          ) : (
            <LockIcon style={{ marginBottom: '4.5px' }} src={passwordShowIconPath} alt="" />
          )
        }
        $errorMessage={errorMessage}
        ref={passwordInputRef || null}
      />
      {suggestion && (
        <SuggestionMessage $errorMessage={errorMessage}>{suggestion}</SuggestionMessage>
      )}
      {!suggestion && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default memo(CommonInputPassword);
