export const PAGE_LINKS = {
  MAIN: '/',
  CONTACT_US: '/contact-us',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  TERMS_AND_CONDITIONS: '/terms',
  SUBSCRIPTION_TERMS: '/subscription-terms',
  MONEY_BACK_POLICY: '/money-back-policy',
  REFUND_POLICY: '/refund-policy',
  ABOUT_US: '/about-us',
  SUPPORT: '/support',
  CHECKOUT: '/checkout',
  PRICING: '/pricing',
  DASHBOARD: '/app/dashboard',
  COOKIE_POLICY: '/cookie-policy',
};
