import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 90px);
  width: calc(100vw - 90px);
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 4px dashed #4988fc;
  background: #fff;

  .ant-progress-inner {
    height: 20px;
    background: var(--Primary-10, #ebeeff);

    .ant-progress-bg {
      height: 20px !important;
      background: #4988fc;
    }
  }

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  color: var(--Blue-50, #4988fc);
  text-align: center;
  /* Desktop/Heading/H2 */

  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 42px */
`;
