import { getCurrencySymbolByCountryCode } from 'helpers/getCurrency';
import { createSelector } from 'reselect';

const baseUserSelector = (state: any) => state?.user;

export const isUserAuthenticated = createSelector(
  baseUserSelector,
  (user) => user?.userData?.status === 'REGISTERED'
);

export const userDataSelector = createSelector(baseUserSelector, (user) => user?.userData || {});

export const userSubscriptionSelector = createSelector(
  baseUserSelector,
  (user) => user?.userData?.subscription
);

export const userIdSelector = createSelector(baseUserSelector, (user) => user?.userData?.id);

export const userEmailSelector = createSelector(baseUserSelector, (user) => user?.userData?.email);

export const userFirstName = createSelector(baseUserSelector, (user) => user?.userData?.firstName);

export const userLastName = createSelector(baseUserSelector, (user) => user?.userData?.lastName);

export const isGoogleUser = createSelector(baseUserSelector, (user) => user?.userData?.googleAuth);

export const isShowUAEAddressSelector = createSelector(baseUserSelector, (user) =>
  ['AE', 'ARE', 'SG', 'SGP'].includes(user?.countryCode)
);

export const isJapanUserSelector = createSelector(baseUserSelector, (user) =>
  ['JP', 'JPN'].includes(user?.countryCode)
);

export const isPolishTurkeyUserSelector = createSelector(baseUserSelector, (user) =>
  ['PL', 'POL', 'TR', 'TUR'].includes(user?.countryCode)
);

export const isTrUserSelector = createSelector(baseUserSelector, (user) =>
  ['TR', 'TUR'].includes(user?.countryCode)
);

export const isLatAmUserSelector = createSelector(baseUserSelector, (user) =>
  [
    'BRA',
    'BR',
    'MX',
    'CO',
    'AR',
    'PE',
    'VE',
    'CL',
    'GT',
    'EC',
    'BO',
    'HT',
    'CU',
    'DO',
    'HN',
    'PY',
    'SV',
    'NI',
    'CR',
    'PA',
    'UY',
    'PR',
    'GP',
    'MQ',
    'GF',
    'MF',
    'BL',
    'JM',
  ].includes(user?.countryCode)
);

export const isReversedCurrencyPositionSelector = createSelector(baseUserSelector, (user) =>
  ['PL', 'POL', 'TR', 'TUR', 'JP', 'JPN'].includes(user?.countryCode)
);

export const isEUUserSelector = createSelector(baseUserSelector, (user) =>
  ['DE', 'DEU', 'FR', 'FRA', 'IT', 'ITA', 'ES', 'ESP', 'GB', 'GBR',
    'NL', 'NLD', 'UK', 'GB', 'GR', 'GRC', 'BE', 'BEL', 'AT', 'AUT', 'CH',
    'CHE', 'CZ', 'CZE', 'DK', 'DNK', 'EE', 'EST', 'FI', 'FIN', 'HU', 'HUN',
    'IE', 'IRL', 'IS', 'ISL', 'LT', 'LTU', 'LU', 'LUX', 'LV', 'LVA', 'MC',
    'MCO', 'NO', 'NOR', 'PT', 'PRT', 'RO', 'ROU', 'SE', 'SWE', 'SI', 'SVN',
    'SK', 'SVK', 'MU', 'UA', 'PL', 'POL', 'RO', 'ROM', 'RS', 'SRB', 'TR',
    
  ].includes(
    user?.countryCode
  )
);

export const isShowUSAAddressSelector = createSelector(baseUserSelector, (user) =>
  ['US', 'USA'].includes(user?.countryCode)
);

export const pageViewSelector = createSelector(baseUserSelector, (user) => user?.pageView);

export const trustPilotLinkSelector = createSelector(
  baseUserSelector,
  (user) => user?.trustPilotLink
);

export const userCurrencySelector = createSelector(baseUserSelector, (user) =>
  getCurrencySymbolByCountryCode(user?.countryCode)
);
