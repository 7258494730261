import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 20px;
  gap: 10px;
  border-radius: 20px;
  background: #fff;
  width: 436px;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  span {
    color: var(--Text-color-tittle, #212e45);
    /* Desktop/Large-600 */
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-right: 5px;
  }

  a {
    display: flex;
    padding-top: var(--Auto-Layouts-gap_10px, 10px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--Auto-Layouts-gap_4px, 4px);
    cursor: pointer;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Text-text_default, #393939);
  text-align: center;
  /* Desktop/H3-600 */
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px; /* 150% */

  @media (max-width: 760px) {
    font-size: 16px;
    line-height: 22px; /* 150% */
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Description = styled.div`
  align-self: stretch;
  /* Caption/Reg */
  color: var(--Text-text_secondary, #757575);
  text-align: center;

  /* Mobile/XSmall-500 */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */

  span,
  a {
    color: var(--Text-text_primary, #4988fc);

    /* Mobile/XSmall-500 */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

export const ContentWrapper = styled.div`
  padding: 0 20px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const SignUpLink = styled.div`
  color: var(--Buttons_Text-primary_default, #4988fc);
  text-align: right;
  /* Desktop/LARGE-UPPER-700 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.18px;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  width: 100%;
`;
