import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useKeyPress from 'hooks/useKeyPress';
import { toggleModal } from 'data/actions/modals';
import openNotification from 'components/commonNotification';
import { ENotification } from 'ts/interfaces/common/notification';
import { sendForgotPassword } from 'data/actions/user';
import closeImagePath from 'assets/close-icon.svg';
import { EModalTypes } from 'ts/enums/modal.types';
import CommonInput from 'components/common/input';
import CommonButton from 'components/common/button';
import loginIcon from 'assets/auth/icon_login.svg';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { AuthFormStep } from './types';
import {
  ForgotPasswordContainer,
  Title,
  InputContainer,
  CloseIconContainer,
  CloseIcon,
  Header,
  SignUpLink,
  ForgotDescription,
  ForgotTitleContainer,
} from './styles';

const ForgotPassword: FC<{ setStep: Dispatch<SetStateAction<AuthFormStep>> }> = ({ setStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'recover_pass_modal_view' }));
  }, [dispatch]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('global.please_enter_valid_email'),
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };

  const onFailed = () => {
    openNotification({
      message: t('auth_form.the_mail_does_not_exist'),
      duration: 5,
      type: ENotification.ERROR,
      description: '',
    });
  };

  const onSuccess = () => {
    dispatch(
      toggleModal({
        visible: true,
        type: EModalTypes.CHECK_YOUR_EMAIL,
        options: { email },
      })
    );
  };

  const handleClose = () => {
    dispatch(sendAnalyticEvent({ event: 'recover_pass_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  };

  const handleResetPassword = () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) return;

    dispatch(sendAnalyticEvent({ event: 'recover_pass_confirm_tap' }));
    dispatch(sendForgotPassword(email, onSuccess, onFailed));
    dispatch(toggleModal({ visible: false }));
  };

  const handleNavigateLogin = useCallback(() => {
    setStep(AuthFormStep['sign-in']);
  }, [setStep]);

  const headerElement = useMemo(
    () => (
      <>
        <Header>
          <span>{t('auth_form.new_user')}</span>
          <SignUpLink onClick={handleNavigateLogin}>
            <img src={loginIcon} alt="login-icon" />
            {t('auth_form.log_in')}
          </SignUpLink>
        </Header>

        <ForgotTitleContainer>
          <Title>{t('auth_form.reset_password')}</Title>
          <ForgotDescription>{t('auth_form.we_ll_send_a_reset_link')}</ForgotDescription>
        </ForgotTitleContainer>
      </>
    ),
    [handleNavigateLogin, t]
  );

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleResetPassword });

  return (
    <ForgotPasswordContainer>
      <CloseIconContainer>
        <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
      </CloseIconContainer>
      {headerElement}

      <InputContainer>
        <CommonInput
          onChange={(value: string) => setEmail(value)}
          value={email}
          error={errors.email}
          placeholder={t('auth_form.email')}
          label={t('auth_form.email_address')}
          name="email"
          type="email"
        />
      </InputContainer>

      <CommonButton
        type="primary"
        disabled={!email?.length}
        onClick={handleResetPassword}
        size="72px"
      >
        {t('global.send')}
      </CommonButton>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
