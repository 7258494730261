import getSymbolFromCurrency from 'currency-symbol-map';

const europeCounties = [
  'ITA',
  'IT',
  'FRA',
  'FR',
  'DEU',
  'DE',
  'ESP',
  'ES',
  'PRT',
  'AUT',
  'SWE',
  'NOR',
  'FIN',
  'DNK',
  'NLD',
  'LTU',
  'LVA',
  'EST',
  'BEL',
  'IRL',
  'SVN',
  'LUX',
  'CYP',
  'MLT',
  'FLK',
  'ISL',
  'GRC',
  'SVK',
  'ROU',
  'CZE',
  'CHE',
  'HUN',
  'BGR',
  'SRB',
  'HRV',
  'BIH',
  'MKD',
  'MNE',
  'ALB',
  'AND',
  'IMN',
  'LIE',
  'MDA',
  'MCO',
  'XKX',
  'SMR',
  'SCG',
];

export const getCurrencySymbol = (currency: string | undefined): string => {
  if (currency === 'JPY') return '円';
  if (currency === 'MXN') return 'MX$';

  return getSymbolFromCurrency(currency ?? '') || '$';
};

export const getCurrencySymbolByCountryCode = (country: string) => {
  if (europeCounties?.includes(country)) return '€';
  if (['GBR']?.includes(country)) return '£';
  if (['JPN']?.includes(country)) return '円';
  return '$';
};
