import { FC, memo, useState } from 'react';

import errorIconPath from 'assets/error-icon.svg';
import { ErrorMessage, InputComponent, InputLabel } from 'components/common/input/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { InputAutocomplete } from './styles';

const mailDomains = [
  '@gmail.com',
  '@yahoo.com',
  '@hotmail.com',
  '@hotmail.co.uk',
  '@icloud.com',
  '@outlook.com',
];

if (
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_API_URL === 'https://fgsirfltd.pdfleader.com'
)
  mailDomains.unshift('@bot.pdfmaster.app');

const EmailAutoCompleteInput: FC<{
  onChange: unknown;
  value: string;
  placeholder?: string;
  label?: string;
  error?: string;
  name?: string;
  type?: string;
}> = ({ label, value, placeholder, error, onChange, type = '', name = '' }) => {
  const [options, setOptions] = useState<{ value: string }[]>([]);

  const handleChange = (value: unknown) => {
    if (typeof onChange === 'function' && typeof value === 'string') onChange(value);
    if (typeof value === 'string' && value?.includes('@') && options?.length) setOptions([]);
  };

  const handleSearch = (text: string) => {
    if (!value?.includes('@') && text?.length && !text?.includes('@'))
      setOptions(mailDomains.map((domain) => ({ value: `${text}${domain}` })));
    else setOptions([]);
  };

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <InputAutocomplete
        onSearch={(text) => handleSearch(text)}
        onSelect={handleChange}
        value={value}
        onChange={handleChange}
        options={options}
        popupClassName="email-auto-complete-input"
      >
        <InputComponent
          name={name}
          type={type}
          value={value}
          placeholder={placeholder || ''}
          $errorMessage={error}
          addonAfter={error?.length && <LazyLoadImage src={errorIconPath} alt="Error icon" />}
        />
      </InputAutocomplete>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default memo(EmailAutoCompleteInput);
