import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import CommonButton from 'components/common/button';
import EmailAutoCompleteInput from 'components/common/inputEmailAutoComplete';
import CommonLink from 'components/common/link';
import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { createAnonymousUser, createUserReveal, getUser } from 'data/actions/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import { isEmailValid } from 'helpers/validation';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';
import loginIcon from 'assets/auth/icon_login.svg';

import { sendAnalyticEvent } from 'data/actions/analytics';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import useDisableScroll from 'hooks/useDisableScroll';
// import { useGoogleAuthButton } from 'hooks/useGoogleAuthButton';
import { beforeUnloadCallback } from 'utils/beforeUnload';
import { CloseIcon, ModalContainer } from '../baseModal/styles';
import { CloseIconContainer } from 'layouts/authForm/styles';
import { PAGE_LINKS } from 'ts/constants/pageLinks';
import {
  Container,
  ContentWrapper,
  Description,
  InputContainer,
  InputsWrapper,
  SignUpLink,
  Title,
  Header,
  ButtonsContainer,
} from './styles';

const EnterEmailAddressModal: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  // const { connectGmailAccount } = useGoogleAuthButton();
  useDisableScroll();
  useCloseModalOnBack();

  const { handleConvertDocument, servicePath, keepOpenedOnSuccess } =
    useSelector(modalOptionsSelector);

  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'registration_modal_view',
      })
    );
  }, [dispatch]);

  const validateEmail = (email: string) => {
    if (!isEmailValid(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('global.please_enter_valid_email'),
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };

  const onFailedSignUp = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'registration_status',
        data: { email, type: 'email', status: 'fail', fail_reason: 'email_already_exist' },
      })
    );
    openNotification({
      message: (
        <div>
          {t('auth_form.this_email_already_exist')}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(toggleModal({ visible: true, type: EModalTypes.AUTH_FORM }))}
          >
            {' '}
            {t('auth_form.log_in')}
          </span>
        </div>
      ),
      type: ENotification.ERROR,
      duration: 10,
    });
  };

  const onSuccessSignUp = () => {
    const handleNavigate = (route: string) => {
      dispatch(toggleModal({ visible: false }));
      navigate(route);
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };
    window.onbeforeunload = null;

    dispatch(
      sendAnalyticEvent({
        event: 'registration_status',
        data: { email, type: 'email', status: 'success' },
      })
    );

    // TODO: Check is this is relevant after analytics check
    dispatch(
      getUser({
        onSuccess: () => {
          if (typeof handleConvertDocument === 'function') {
            handleConvertDocument();
            if (!keepOpenedOnSuccess) {
              dispatch(toggleModal({ visible: false }));
            }
            return;
          }
          handleNavigate(PAGE_LINKS.DASHBOARD);
          if (!keepOpenedOnSuccess) {
            dispatch(toggleModal({ visible: false }));
          }
        },
        onFailed: () =>
          dispatch(
            createAnonymousUser(() => {
              if (typeof handleConvertDocument === 'function') {
                handleConvertDocument();
                if (!keepOpenedOnSuccess) {
                  dispatch(toggleModal({ visible: false }));
                }
                return;
              }
              handleNavigate(PAGE_LINKS.DASHBOARD);
              if (!keepOpenedOnSuccess) {
                dispatch(toggleModal({ visible: false }));
              }
            })
          ),
      })
    );

    // if (typeof handleConvertDocument === 'function') {
    //   handleConvertDocument();
    //   if (!keepOpenedOnSuccess) {
    //     dispatch(toggleModal({ visible: false }));
    //   }
    //   return;
    // }
    // handleNavigate('/app/dashboard');
    // if (!keepOpenedOnSuccess) {
    //   dispatch(toggleModal({ visible: false }));
    // }
  };

  const handleDownLoadFile = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'registration_continue_tap',
        data: {
          button_state: !email ? 'disabled' : 'active',
        },
      })
    );

    if (!email) return;

    const isValidEmail = validateEmail(email);
    if (!isValidEmail) return;

    dispatch(createUserReveal({ email, onSuccess: onSuccessSignUp, onFailed: onFailedSignUp }));
  };

  const handleLogin = () => {
    dispatch(
      toggleModal({
        type: EModalTypes.AUTH_FORM,
        visible: true,
        options: {
          handleConvertDocument,
          servicePath,
          reveal: true,
        },
      })
    );
  };

  const handleModalClose = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'enter_email_close_tap',
      })
    );
    window.onbeforeunload = beforeUnloadCallback;
    dispatch(toggleModal({ visible: false }));
  };

  const descriptionElement = useMemo(
    () => (
      <Description>
        {t('auth_form.by_creating_account_means')}{' '}
        <CommonLink size="13.4px" to="/terms" target="_blank">
          {t('global.terms_and_conditions')}
        </CommonLink>
        , {t('auth_form.and_our')}{' '}
        <CommonLink size="13.4px" to="/privacy-policy" target="_blank">
          {t('global.privacy_policy')}
        </CommonLink>
      </Description>
    ),
    [t]
  );

  return (
    <ModalContainer>
      <Container>
        <CloseIconContainer>
          <CloseIcon onClick={() => handleModalClose()} src={closeImagePath} alt="" />
        </CloseIconContainer>

        <ContentWrapper>
          <>
            <Header>
              <span>{t('auth_form.have_an_account')}</span>
              <SignUpLink onClick={handleLogin}>
                <img src={loginIcon} alt="login-icon" />
                {t('auth_form.log_in')}
              </SignUpLink>
            </Header>

            <Title>{t('auth_form.sign_up')}</Title>
          </>

          <InputsWrapper>
            <InputContainer>
              <EmailAutoCompleteInput
                onChange={(value: string) => setEmail(value)}
                value={email}
                placeholder={t('auth_form.email')}
                label={t('auth_form.email_address')}
                error={errors.email}
              />
            </InputContainer>
          </InputsWrapper>

          <ButtonsContainer>
            <CommonButton size="72px" type={!email ? '' : 'primary'} onClick={handleDownLoadFile}>
              {t('global.continue')}
            </CommonButton>
          </ButtonsContainer>

          {descriptionElement}
        </ContentWrapper>
      </Container>
    </ModalContainer>
  );
};

export default EnterEmailAddressModal;
