import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import openNotification from 'components/commonNotification';
import { sendAnalyticEvent } from 'data/actions/analytics';
import {
  downloadDocument,
  getMergedFile,
  getUploadLink,
  mergeDocuments,
  setConvertDocumentData,
  setMergeDocumentData,
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import {
  isUserAuthenticated,
  userEmailSelector,
  userSubscriptionSelector,
} from 'data/selectors/user';
import { countPdfPages } from 'helpers/countPdfPages';
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_RESULT_COUNT_RETRIES } from 'ts/constants/general';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';
import { IMergeDocumentDto } from 'ts/interfaces/documents/document';
import { IService } from 'ts/interfaces/services/service';
import { convertToPdf } from 'utils/convertToPdf';

const useFileUploadAndMerge = ({ service }: { service?: IService }) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const email = useSelector(userEmailSelector);

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter,
  }: {
    success: boolean;
    size: number;
    errorCode?: number;
    fileCounter?: number;
  }) => {
    dispatch(
      sendAnalyticEvent({
        event: 'file_upload_status',
        data: {
          status: success ? 'success' : 'fail',
          place: 'additional',
          errorCode,
          accurate_size: size / 1000000,
          fileCounter: fileCounter || 1,
          file_format: '.pdf',
          is_validation_error: success ? 'false' : 'true',
        },
      })
    );
  };

  const sendDownloadAgainMessage = (documentId: string) => {
    openNotification({
      message: (
        <div>
          {t('notifications.download_started')}{' '}
          <span className="link" onClick={() => dispatch(downloadDocument(documentId))}>
            {t('notifications.download_again')}
          </span>
        </div>
      ),
      type: ENotification.INFO,
      duration: 5,
    });
  };

  const handleMergeDocument = (
    dataToMerge: IMergeDocumentDto[],
    signUp?: boolean,
    subscription?: any
  ) => {
    if (!userSubscription && !subscription?.id) {
      return setTimeout(
        () => {
          dispatch(mergeDocuments(dataToMerge));
          dispatch(toggleModal({ visible: false }));
          navigate('/choose-plan');
          window.scrollTo(0, 0); // Scrolls to the top of the page
        },
        signUp ? 0 : 1000
      );
    }

    const onSuccess = (res: any) => {
      let countRetries = 0;
      const interval = setInterval(() => {
        const onSuccessCallback = (res: any) => {
          clearInterval(interval);

          // awaiting the downloading animation
          setTimeout(() => {
            if (res?.processing_status === 'FAILED')
              return dispatch(
                toggleModal({
                  type: EModalTypes.FILE_UPLOAD_ERROR,
                  visible: true,
                })
              );

            dispatch(toggleModal({ visible: false }));
            sendDownloadAgainMessage(res?.id);

            dispatch(downloadDocument(res?.id));
          }, 1000);
        };
        countRetries += 1;
        if (countRetries < GET_RESULT_COUNT_RETRIES)
          dispatch(getMergedFile(res?.fileId, onSuccessCallback));
        else {
          clearInterval(interval);
          dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
        }
      }, 1500);
    };

    const onFailed = () => {
      navigate('/choose-plan');
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };

    dispatch(mergeDocuments(dataToMerge, onSuccess, onFailed));
  };

  const handleMergeFiles = (dataToMerge: IMergeDocumentDto[]) => {
    if (!isAuth) {
      return setTimeout(() => {
        dispatch(
          toggleModal({
            type: EModalTypes.ENTER_EMAIL_ADDRESS,
            visible: true,
            options: {
              handleConvertDocument: (subscription: any) =>
                handleMergeDocument(dataToMerge, true, subscription),
              signUp: true,
              servicePath: service?.path,
            },
          })
        );
      }, 2000);
    }
    handleMergeDocument(dataToMerge);
  };

  const handleUploadFilesByLinkToS3 = async (files: File[], uploadLinks: string[]) => {
    try {
      dispatch(toggleModal({ type: EModalTypes.PROCESSING_MERGE, visible: true }));
      const resData: any = await Promise.allSettled(
        uploadLinks.map((link, index) => axios.put(link, files[index], { headers: {} }))
      );

      const fulfilledResponses = resData
        .filter((result: any) => result.status === 'fulfilled')
        .map((result: any) => result.value);

      if (files.length !== fulfilledResponses.length) {
        console.error('Error uploading file:----->');
      }

      const keys = fulfilledResponses.map((item: any) =>
        getFileKeyFromAWSLink(item?.request?.responseURL)
      );

      const pagesCount = await Promise.all(
        files.map(async (file) => (await countPdfPages(file)) || 1)
      );

      const dataToMerge: IMergeDocumentDto[] = files.map((file, index) => ({
        filename: file?.name,
        size: file.size,
        key: keys[index],
        pagesCount: pagesCount[index],
        orderIndex: index,
      }));

      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(
          setConvertDocumentData({
            serviceType: service?.serviceType,
            filename: 'merged.pdf',
            size: files[0]?.size,
            key: keys[0],
            pagesCount: 1,
            from: 'PDF',
            to: 'PDF',
            url: '',
          })
        );

        // set converted data to localStorage and use this data for google auth
        localStorage.setItem(
          'dataToConvert',
          JSON.stringify({
            file: dataToMerge,
            service: service?.path,
            email: email,
            serviceType: service?.serviceType,
          })
        );

        analyticsEventUpload({ size: files[0]?.size, success: true });
        handleMergeFiles(dataToMerge);
      }, 5000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleUploadImagesFiles = async (files: File[]) => {
    const pdfFilesFromImages = await Promise.all(files.map((file) => convertToPdf(file)));
    if (!pdfFilesFromImages?.length) return;

    dispatch(
      sendAnalyticEvent({
        event: 'features_tap',
        data: {
          features_name: service?.path?.replace('/', '') || '',
          method: 'click',
        },
      })
    );

    dispatch(
      getUploadLink({
        filename: pdfFilesFromImages?.map((file) => file?.name),
        onSuccess: (res: any) => {
          handleUploadFilesByLinkToS3(
            pdfFilesFromImages,
            res.map((item: any) => item?.url)
          );
        },
        onFailed: () => {
          dispatch(sendAnalyticEvent({ event: 'complete_merge_error' }));
        },
        service,
      })
    );
  };

  // upload to s3 bucket and convert file
  const handleUploadFiles = (files: File[]) => {
    if (!files?.length) return;

    dispatch(
      sendAnalyticEvent({
        event: 'features_tap',
        data: {
          features_name: service?.path?.replace('/', '') || '',
          method: 'click',
        },
      })
    );

    dispatch(
      getUploadLink({
        filename: files?.map((file) => file?.name),
        onSuccess: (res: any) => {
          handleUploadFilesByLinkToS3(
            files,
            res.map((item: any) => item?.url)
          );
        },
        onFailed: () => {
          dispatch(sendAnalyticEvent({ event: 'complete_merge_error' }));
        },
        service,
      })
    );
  };

  const handleSetMergeFilesList = useCallback(
    (filesList: { file: File; thumbnail?: string | null }[]) => {
      dispatch(setMergeDocumentData(filesList));
    },
    [dispatch]
  );

  return {
    handleSetMergeFilesList,
    handleUploadFiles,
    handleMergeDocument,
    handleUploadImagesFiles,
  };
};

export default useFileUploadAndMerge;
