import { CommonTextBody, CommonTextH1 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ebeeff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  position: relative;
  background: #fff;
  background-size: cover;
`;

export const HeroHeader = styled(CommonTextH1)`
  width: 100%;
  max-width: 562px;
  color: var(--Text-text_default, #393939);
  /* Desktop/H1-900 */
  font-family: Montserrat;
  font-size: 56px;
  font-style: normal;
  font-weight: 900;
  line-height: 68px; /* 121.429% */

  @media (max-width: 760px) {
    font-size: 24px;
    line-height: 32px; /* 129.167% */
    text-align: center;
  }
`;

export const HeroSecondaryHeader = styled(CommonTextBody)`
  color: var(--Text-text_default, #393939);
  /* Desktop/H3-600 */
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px; /* 150% */
  margin-top: 24px;
  max-width: 500px;

  @media (max-width: 760px) {
    font-size: 16px;
    line-height: 22px; /* 150% */
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  padding: 0 112px;
  margin: 0 auto;
  margin-top: 60px;

  button {
    height: 68px;
  }

  @media (max-width: 760px) {
    padding: 0 16px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Features = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 32px;
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1 0 0;

  span {
    color: var(--Text-text_default, #393939);
    /* Desktop/Extra Large-500 */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
  }

  @media (max-width: 1040px) {
    span {
      font-size: 16px;
      line-height: 130%; /* 20.8px */
    }
  }
`;

export const CookiesBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
`;
