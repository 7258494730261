export interface AnalyticsEvent {
  event: string;
  data?: Record<string, any>;
}

export enum FileUploadingModalViewTypes {
  editFunnels = 'edit funnels',
  convertFunnels = 'convert funnels',
  otherFunnels = 'other funnels',
}

export enum UploadingModalTypes {
  CONVERT = 'convert',
  EDIT = 'edit',
  OTHER = 'other',
}

export interface PreviewStatusView extends AnalyticsEvent {
  event: 'preview_status_view';
  data: {
    status: 'success' | 'error' | 'impossible';
  };
}
