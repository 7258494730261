import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDisableScroll from 'hooks/useDisableScroll';
import { ModalContainer } from '../baseModal/styles';
import { Container, Description } from './styles';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import { Progress } from 'antd';

const ThankYouPaymentModal: FC = () => {
  const [percent, setPercent] = useState<number>(0);

  const { t } = useTranslation();
  useDisableScroll();
  useCloseModalOnBack();

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prev) => {
        if (prev >= 100) {
          return 0;
        }
        return prev + 20;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalContainer>
      <Container>
        <Progress
          type="circle"
          percent={percent}
          steps={{ count: 5, gap: 7 }}
          trailColor="rgba(0, 0, 0, 0.06)"
          strokeColor="#4988FC"
          strokeWidth={10}
          showInfo={false}
        />
        <Description>{t('Thanks for the payment — your file is on its way!')}</Description>
      </Container>
    </ModalContainer>
  );
};

export default ThankYouPaymentModal;
