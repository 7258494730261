import { Input } from 'antd';
import styled from 'styled-components';

export const InputLabel = styled.span`
  color: var(--Text-text_default, #393939);
  /* Mobile/SMALL-UPPER-700 */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
`;

export const ErrorMessage = styled.div`
  margin-top: 4px;
  color: var(--Notifications-notif_error, #e65218);
  /* Mobile/Small-500 */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
`;

export const InputComponent = styled(Input)<{ $errorMessage?: string }>`
  display: flex;
  height: auto;
  align-items: center;
  padding: 12px 16px 12px 20px !important;
  min-height: 48px !important;

  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px !important;
  width: 100%;
  margin-top: 4px !important;

  color: #393939 !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  border: ${(props) =>
    props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#DFE4EA !important'};
  background: ${(props) =>
    props?.$errorMessage ? 'rgba(230, 82, 24, 0.08) !important' : '#fff !important'};

  &::placeholder {
    color: #b4b4b4 !important;
    /* Body/Reg */

    font-size: 14px !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &:hover {
    border: ${(props) =>
      props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#393939 !important'};
  }

  &:focus,
  &:active {
    border: ${(props) =>
      props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#4988FC !important'};
  }

  .ant-input-group-addon {
    border: none !important;
    background: ${(props) => (props?.$errorMessage ? '#fef2ee !important' : '#fff !important')};
    padding: 0 !important;
    line-height: 0;
  }

  input {
    border: none !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 0 10px !important;
    outline: none !important;
    background: ${(props) => (props?.$errorMessage ? '#fef2ee !important' : '#fff !important')};
    text-transform: none !important;

    font-weight: 600 !important;

    &:active,
    &:focus,
    &:hover {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;
