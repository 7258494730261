import { Input } from 'antd';
import styled from 'styled-components';

export const InputPassword = styled(Input.Password)<{ $errorMessage?: string }>`
  display: flex !important;
  height: auto;
  padding: 12px 16px 12px 12px !important;
  align-items: end;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px !important;
  width: 100%;
  margin-top: 4px !important;
  color: #393939 !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  border: ${(props) =>
    props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#DFE4EA !important'};
  background: ${(props) =>
    props?.$errorMessage ? 'rgba(230, 82, 24, 0.08) !important' : '#fff !important'};

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &:hover {
    border: ${(props) =>
      props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#393939 !important'};
  }

  &:focus,
  &:active {
    border: ${(props) =>
      props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#4988FC !important'};
  }

  .ant-input-group-addon {
    border: none !important;
    background: ${(props) => (props?.$errorMessage ? '#fef2ee !important' : '#fff !important')};
    padding: 0 !important;
    line-height: 0 !important;
  }

  .ant-input-group-wrapper {
    padding: 12px 20px !important;
    color: var(--Text-secondary, #4a4e62);
  }

  .ant-input-affix-wrapper {
    border: none !important;
    font-size: 16px !important;

    outline: none !important;
    box-shadow: none !important;
    padding: 0 0 0 10px !important;

    &:active,
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  input::placeholder {
    color: #b4b4b4 !important;
    /* Body/Reg */

    font-size: 14px !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
  }

  input {
    border: none !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 0 !important;
    outline: none !important;
    background: ${(props) => (props?.$errorMessage ? '#fef2ee !important' : '#fff !important')};
    color: var(--Text-secondary, #4a4e62);
    font-weight: 400 !important;

    &:active,
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;

export const InputLabel = styled.span`
  color: var(--Text-text_default, #393939);
  /* Mobile/SMALL-UPPER-700 */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  color: #f23030;
  /* Caption/Reg */

  font-size: 13.4px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 21.44px */
`;

export const SuggestionMessage = styled.div<{ $errorMessage?: string }>`
  margin-top: 4px;
  color: ${(props) => (props?.$errorMessage ? '#F23030' : '#393939')};
  /* Mobile/Small-500 */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
`;

export const LockIcon = styled.img`
  cursor: pointer;
`;

export const ForgotContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ForgotPasswordButton = styled.div`
  color: var(--Text-text_primary, #4988fc);
  text-align: right;
  /* Mobile/Small-500 */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  cursor: pointer;
`;
