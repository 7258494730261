import { EServiceType, IService } from "ts/interfaces/services/service";
import Pdf2PngIcon from 'assets/converterIcons/pdf_2_png.svg';

export const mainPageService = (t: any): IService => {
  return {
    serviceType: EServiceType.CONVERTOR,
    path: '/',
    funnel: 'main_page',
    translateKey: 'main_page',
    name: t('main_page.name'),
    nameKey: 'main_page.name',
    icon: Pdf2PngIcon,
    from: 'PDF',
    to: 'DOC',
    availableFormats: '.pdf',
  };
};