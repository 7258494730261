import { sendAnalyticEvent } from 'data/actions/analytics';
import { DEFAULT_LANGUAGE, languages, SupportedLanguage } from 'i18n';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

export const useChangeLanguage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (lng: SupportedLanguage) => {
    const prevLanguage = i18n?.language;
    i18n?.changeLanguage(lng);

    const allPrefixes = languages.map((lang) => ({ base: `/${lang}`, sub: `/${lang}/` }), {});

    if (prevLanguage === lng) return;

    dispatch(sendAnalyticEvent({ event: 'change_language_tap', data: { type: lng } }));

    const pathnameWithoutLocale = allPrefixes?.reduce(
      (acc, prefixes) => (acc === prefixes.base ? '/' : acc.replace(prefixes.sub, '/')),
      location.pathname
    );
    // const pathnameWithoutLocale = location?.pathname?.replace(`/${prevLanguage}`, '');

    if (lng === DEFAULT_LANGUAGE) {
      navigate(pathnameWithoutLocale);
    } else {
      navigate(`/${lng}${pathnameWithoutLocale}`);
    }
  };
};

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  return i18n?.language;
};

export const useBasePath = () => {
  const { i18n } = useTranslation();
  return useMemo(() => {
    return i18n?.language === DEFAULT_LANGUAGE ? '' : `/${i18n?.language}`;
  }, [i18n?.language]);
};

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const basePath = useBasePath();

  return useCallback(
    (path: string, options?: NavigateOptions) => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
      if (options) {
        navigate(`${basePath}${path}`, options);
        return;
      }
      navigate(`${basePath}${path}`);
    },
    [navigate, basePath]
  );
};
