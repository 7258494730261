import { AutoComplete } from 'antd';
import styled from 'styled-components';

export const InputAutocomplete = styled(AutoComplete)<{
  $errorMessage?: string;
}>`
  width: 100%;
  height: auto !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px !important;

  .ant-select-selector {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    border: none !important;
  }

  .ant-select-selection-placeholder {
    color: #9ca3af !important;
    /* Body/Reg */

    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130%; /* 20.8px */
  }

  input {
    border-radius: 6px !important;

    color: var(--Text-text_default, #393939);

    /* Mobile/SMALL-UPPER-700 */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    outline: none !important;
    box-shadow: none !important;
  }
`;
