import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 44px 20px 20px 20px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 485px;
  min-height: 544px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Dark-Dark, #111928);
  text-align: center;
  margin-bottom: 15px;
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 0 10px;
`;

export const Description = styled.div`
  color: var(--Colors-black-grey-white-grey-60, #616161);
  text-align: center;

  /* Desktop/Body/Reg */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
`;

export const DescriptionContainer = styled.div`
  color: var(--Dark-Dark, #111928);
  /* Body Large/Bold */

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin: 35px 0;
  display: flex;
  align-items: center;
`;

export const FormatsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 25px 0;
`;

export const FormatItem = styled.div<{ current?: boolean }>`
  display: flex;
  align-items: center;
  width: 205px;
  min-width: 205px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--Radius-xs, 5px);
  border-radius: var(--Radius-xs, 5px);
  border: ${(props) => (props?.current ? '1px solid #4988FC' : '1px solid #EBEEFF')};
  background: ${(props) => (props?.current ? '#EBEEFF' : '#FFF')};
  cursor: pointer;

  &:hover {
  }

  @media (max-width: 760px) {
    width: fit-content;
    min-width: fit-content;
    gap: 10px;
  }

  @media (max-width: 390px) {
    width: 100%;
    min-width: 100px;
    padding: 10px 10px;

    .format-icon {
      width: 28px;
      height: 28px;
    }
  }
`;

export const FormatLabel = styled.div`
  color: var(--Text-color-tittle, #212e45);

  /* Desktop/Body/Bold */

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */

  @media (max-width: 390px) {
    font-size: 16px;
  }
`;

export const FormatImage = styled.img``;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  margin-top: 25px;

  button {
    width: 100%;
  }
`;
